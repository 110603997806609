/*
 * @Author: DragonL
 * @Date: 2021-09-23 09:41:24
 * @LastEditors: DragonL
 * @LastEditTime: 2021-12-14 14:47:42
 * @Description: 
 */
import axios from "axios"
export function request(config) {
    return new Promise((resolve, reject) => {
        //创建axios实例
        const instance = axios.create({
            baseURL: window.AllUrl,
            // baseURL: "https://xy-website-api-test.xinyuanact.com",
            // baseURL: '/ccc',
            timeout: 50000
        })

        instance.interceptors.request.use(function (config) {
            // config.headers['X-Requested-With'] = 'XMLHttpRequest'
            console.warn(config,"config")
            // 在发送请求之前做些什么
            return config;
          }, function (error) {
            console.log(error);
            // 对请求错误做些什么
            return Promise.reject(error);
          });
        instance.interceptors.response.use(
            response => {

                console.warn(response,"response")




                // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
                // 否则的话抛出错误
                if (response.status === 200) {
                    return Promise.resolve(response);
                } else {
                    return Promise.reject(response);
                }
            },
            // 服务器状态码不是2开头的的情况
            // 这里可以跟你们的后台开发人员协商好统一的错误状态码
            // 然后根据返回的状态码进行一些操作，例如登录过期提示，错误提示等等
            // 下面列举几个常见的操作，其他需求可自行扩展
            error => {
                console.warn(error.response)
                // if (error.response.status) {
                //     switch (error.response.status) {
                //         // 401: 未登录
                //         // 未登录则跳转登录页面，并携带当前页面的路径
                //         // 在登录成功后返回当前页面，这一步需要在登录页操作。
                //         case 401:
                //             // vant.Toast.fail("身份验证失败，请关闭重新进入。");
                //             break;

                //         // 403 token过期
                //         // 登录过期对用户进行提示
                //         // 清除本地token和清空vuex中token对象
                //         // 跳转登录页面
                //         case 403:
                //             // vant.Toast.fail("登录过期，请关闭重新进入。");
                //             // 清除token
                //             break;

                //         // 404请求不存在
                //         case 404:
                //             // vant.Toast.fail("您访问的网页不存在。");
                //             break;
                //         // 其他错误，直接抛出错误提示
                //         case 413:
                //             console.warn("太大");
                //             break;
                //         default:
                //         // vant.Toast.fail(error.response.data.message);
                //     }
                //     return Promise.reject(error.response);
                // }
            }
        );
        //发送真正的网络请求
        instance(config)
            .then(res => {
                // console.log(res) //要回调出去
                resolve(res) //回调
            })
            .catch(err => {
                // console.log(err) //要回调出去
                reject(err) //回调
            })
    })
}