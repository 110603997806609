<template>
  <div class="black" ref="bscrolld" @scroll.prevent="ddd">
    <div class="white">
      <img src="../../assets/make/news/close.png" class="close" @click="emit" />
      <div class="title">
        <div class="hotd" v-if="is_pressing === 1">热</div>
        <div>{{ object.name }}</div>
      </div>
      <div class="word up">
        <span>{{ object.category }}</span> |
        <span>{{ object.tag }}</span>
      </div>
      <div class="word">{{ object.workspace }}</div>
      <div class="aa">
        <div class="say">职位描述</div>
        <div class="say-t"></div>
      </div>
      <div class="content">
        <div v-html="object.content"></div>
      </div>
      <div class="divider"></div>
      <div class="button-a">
        <!-- <div><div class="geshi">上传简历格式word\ppt\pdf\jpg\zip</div></div> -->
        <!-- <div class="button" :class="{ disable: disable }" @click="show = true">
          申请职位
          <input
            :disabled="disable"
            type="file"
            class="file"
            ref="file"
            @change="loadFile"
            id="file"
          />
        </div>-->
        <div class="button" :class="{ disable: disable }" @click="show = true">
          申请职位
        </div>
        <div class="backgg" v-if="show" @click.self="show = false">
          <div class="padding-gg">
            <div class="dddd">请提交以下任意格式的文件</div>
            <div class="godd" ref="file">
              <div v-for="(item, index) in list" :key="index" class="gg">
                <el-button style="width: 100%">{{ item }}</el-button>
                <input
                  :disabled="disable"
                  type="file"
                  class="file"
                  @change="loadFile(index)"
                  id="file"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ElMessage, ElButton, ElLoading } from "element-plus";
import { resumeList } from "../../utils/api";
// import $ from "jquery";
export default {
  components: {
    ElButton,
    // ElPopover,
  },
  mounted() {
    document.body.style = `overflow: hidden;
height: 100%;`;
  },
  beforeUnmount() {
    document.body.style = `overflow: unset;
height: unset;`;
  },
  props: {
    object: {
      type: Object,
      default: () => {},
    },
    joblist: {
      type: Array,
      default: () => [],
    },
    is_pressing: {
      type: Number,
      default: 0,
    },
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      message: "",
      disable: false,
      show: false,
      list: ["docx", "doc", "ppt", "pdf", "jpg", "zip"],
      loading: null,
    };
  },

  methods: {
    ddd(e) {
      console.warn("gg");
      e.preventDefault();
    },
    emit() {
      this.$emit("close");
    },
    // change(id) {
    //   for (let a = 0; a < this.joblist.length; a++) {
    //     if (this.joblist[a].id === id) {
    //       return this.joblist[a].name;
    //     }
    //   }
    // },
    conver(limit) {
      var size = "";
      if (limit < 0.1 * 1024) {
        //如果小于0.1KB转化成B
        size = limit.toFixed(2) + "B";
      } else if (limit < 0.1 * 1024 * 1024) {
        //如果小于0.1MB转化成KB
        size = (limit / 1024).toFixed(2) + "KB";
      } else if (limit < 0.1 * 1024 * 1024 * 1024) {
        //如果小于0.1GB转化成MB
        size = (limit / (1024 * 1024)).toFixed(2) + "MB";
      } else {
        //其他转化成GB
        size = (limit / (1024 * 1024 * 1024)).toFixed(2) + "GB";
      }

      var sizestr = size + "";
      var len = sizestr.indexOf(".");
      var dec = sizestr.substr(len + 1, 2);
      if (dec == "00") {
        //当小数点后为00时 去掉小数部分
        return sizestr.substring(0, len) + sizestr.substr(len + 3, 2);
      }
      return sizestr;
    },
    loadFile(index) {
      // let that = this;
      // console.warn(this.$refs.file.children[index].children[1].files[0]);
      if (
        this.check(
          this.$refs.file.children[index].children[1].files[0].name,
          index
        )
      ) {
        var formData = new FormData();
        console.warn(
          this.$refs.file.children[index].children[1].files[0].size,
          "size"
        );
        if (
          20 * 1024 * 1024 -
            this.$refs.file.children[index].children[1].files[0].size <
          0
        ) {
          ElMessage({
            showClose: true,
            message: "上传文件过大，请上传小于20MB的文件",
            type: "error",
          });
          this.$refs.file.children[index].children[1].value = "";
          return;
        }
        formData.append(
          "upload_file",
          this.$refs.file.children[index].children[1].files[0]
        );
        formData.append("position_id", this.id);
        this.disable = true;
        // $.ajax({
        //   contentType: false,
        //   // contentType: "multipart/form-data",
        //   url: "https://xy-website-api-test.xinyuanact.com/api/resume/deliver",
        //   type: "POST",
        //   data: formData,
        //   processData: false,
        //   // processData: false,
        //   dataType: "json",
        //   cache: false,
        //   //    配置参数
        // })
        //   .done(function (res) {
        //     that.message = res.msg;
        //     if (res.data.code == 1) {
        //       ElMessage({
        //         showClose: true,
        //         message: res.data.msg,
        //         type: "error",
        //       });
        //     } else {
        //       ElMessage({
        //         showClose: true,
        //         message: "上传成功",
        //         type: "success",
        //       });
        //       that.disable = false;
        //     }
        //     //    以此类推
        //   })
        //   .fail(function () {
        //     console.log("请求失败");
        //   });
        const a = ElLoading.service({
          lock: true,
          text: "正在上传，请稍等",
          background: "rgba(0, 0, 0, 0.7)",
        });
        resumeList(formData)
          .then((res) => {
            this.message = res.msg;
            if (res.data.code == 1) {
              ElMessage({
                showClose: true,
                message: res.data.msg,
                type: "error",
              });
              a.close();
              this.$refs.file.children[index].children[1].value = "";
            } else {
              ElMessage({
                showClose: true,
                message: "上传成功",
                type: "success",
              });
              a.close();
              this.$refs.file.children[index].children[1].value = "";
              this.disable = false;
              this.show = false
            }
          })
          .catch((err) => {
            console.warn(err);
            ElMessage({
              showClose: true,
              message: err,
              type: "error",
            });
            this.$refs.file.children[index].children[1].value = "";
            this.disable = false;
          });
      }
    },
    check(name, index) {
      var regd = new RegExp(`^.*\\.(?:${this.list[index]})$`, "i");
      if (!regd.test(name)) {
        console.warn("fail");
        ElMessage({
          duration: 2000,
          showClose: true,
          message: "请上传正确格式文件：." + this.list[index],
          type: "error",
        });
        this.$refs.file.children[index].children[1].value = "";
        return false;
      } else {
        console.warn("success");
        return true;
      }
    },
  },
};
</script>
<style scoped>
.padding-gg{
  padding: 20px;
  background-color: white;
}
.backgg {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.55);
}
.geshi {
  position: absolute;
  text-align: center;
  right: 0;
  bottom: 0;
}
.gg {
  position: relative;
}

.tippp {
  color: #ff3333;
  font-size: 13px;
}
.dddd {
  text-align: center;
  height: 30px;
  line-height: 30px;
}
.godd {
  display: flex;
  height: 250px;
  flex-direction: column;
  justify-content: space-between;
  /* align-content: space-between; */
}
.fiee {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 20px;
  /* width: 100px; */
  height: 40px;
  display: flex;
  justify-content: space-between;
  background: white;

  z-index: 9999;
}

.cddd {
  width: 200px;
  /* border: 1px solid ; */
  background: gray;
  border-radius: 10px;
}
.black {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.45);
}
.disable {
  opacity: 0.5 !important;
}
.file:disabled {
  cursor: wait !important;
}
.hotd {
  font-size: 15px;
  font-family: HarmonyOS_Sans_SC;
  font-weight: 400;
  color: #ffffff;
  display: inline-block;
  text-align: center;
  width: 21px;
  height: 19px;
  background: #ff3333;
  line-height: 19px;
  margin-right: 4px;
  margin-top: 3px;
}
.button-a {
  display: flex;
  justify-content: center;
  position: relative;
}

.content {
  height: calc(182px + 30px + 182px);
  overflow-y: scroll;
  font-size: 16px;
}

.content::-webkit-scrollbar {
  display: none;
}
.white {
  width: 824px;
  height: 733px;
  padding: 36px;
  background: #ffffff;
  position: relative;
  letter-spacing: 1px;
}
.aa {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 31px;
}
.say-t {
  width: 749px;
  height: 1px;
  background: #3c3c3c;
  /* border: 1px solid #3c3c3c; */

  /* flex: 1; */
}
.close {
  position: absolute;
  width: 17px;
  height: auto;
  right: 36px;
  top: 36px;
  cursor: pointer;
}
.say {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #3c3c3c;
}
.hot {
  width: 21px;
}
.title {
  font-size: 21px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  margin-bottom: 22px;
  display: flex;
  line-height: 28px;
  align-items: center;
}
.word {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #3c3c3c;
  margin-bottom: 40px;
}
.up {
  margin-bottom: 17px;
}
.divider {
  margin-bottom: 54px;
  margin-top: 40px;
  height: 1px;
  /* border: 1px solid #3c3c3c; */
  background: #3c3c3c;
}

.button {
  width: 193px;
  height: 51px;
  background: #2b2b2b;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #cc73ff;
  line-height: 51px;
  text-align: center;
  cursor: pointer;
  position: relative;
}

/* .file {
  width: 193px;
  height: 51px;
} */
/* /deep/ #file-upload-button {
  width: 193px;
  height: 51px;
} */
.file {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 40px;
  cursor: pointer;
  opacity: 0;
}

/* .file:hover:after{
  content: '上传简历格式word\ppt\pdf\jpg\zip';
  opacity: 1 !important;
} */

/* input[id="file"] {
  width: 193px;
  height: 51px;
} */
</style>