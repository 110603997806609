import { request } from "./request";
// import Qs from 'qs'
// import qs from 'qs';
// import axios from "axios"
//首页banners
export function banners() {    //可以   确定没有问题
    return request({
        method: 'get',
        url: '/api/home/banners'
        // url: "static/home.json"
    })
}


//新闻列表
export function newsList(params) {     //可以  确定没有问题
    return request({
        method: 'get',
        params,
        url: '/api/news/list'
        // url: "/webapi/news"
        // url: "static/news.json"
    })
}

//新闻详情
export function newsDetail(params) {     //可以  确定没有问题 
    return request({
        method: 'get',
        url: '/api/news/detail' + `/${params}`
        // url: "static/newsdetails.json"
    })
}

//游戏列表
export function gameList() {       //可以  确定没有问题
    return request({
        method: 'get',
        caches: false,
        url: '/api/game/list'
        // url: "static/games.json"
    })
}

//企业文化列表
export function cultureList() {        //可以  确定没有问题
    return request({
        method: 'get',
        url: '/api/culture/list'
        // url: "static/test.json"
    })
}


//招聘岗位列表
export function positonList(params) {    //可以
    return request({
        method: 'get',
        url: '/api/position/list',
        // url: 'static/position.json',
        params
    })
}


//岗位详情
export function positonDetail(params) {     //可以
    return request({
        method: 'get',
        url: '/api/position/detail' + `/${params}`
        // url: 'static/positiondetail.json'
    })
}

//岗位类别
export function positonCategoryList() {     //可以
    return request({
        method: 'get',
        url: '/api/position/category'
        // url: 'static/positiondetail.json'
    })
}


//投递简历
export function resumeList(data) {
    // let a = axios.create({
    //     baseURL: process.env.VUE_APP_BASE_API,
    //     // url: '/api/resume/deliver',
    //     headers: { 'Content-Type': 'multipart/form-data' },
    //     timeout: 5000
    // })
    // a.post('/api/resume/deliver', data).then(res => {
    //     console.warn(res)
    // })
    return request({
        baseURL:window.CUrl,
        // baseURL:'https://xy-website-admin-test.xinyuanact.com',
        method: 'post',
        url: '/api/resume/deliver',
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
    })
}




//首页导航
export function getHomeList() {     //可以
    return request({
        method: 'get',
        url: '/api/home/nav'
        // url: 'static/positiondetail.json'
    })
}