<template>
  <div class="title">
    <div class="eng" ref="end">
      {{ enName }}
    </div>
    <div class="cha" :style="{ color: color, opacity: opacity }">
      {{ title }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "热招岗位",
    },
    enName: {
      type: String,
      default: "hawdawdawdtdb",
    },
    color: {
      type: String,
      default: "#212121",
    },
    opacity: {
      type: String,
      default: '0.85',
    },
  },
  data() {
    return {
      a: null,
    };
  },
  mounted() {},
};
</script>
<style scoped>
.title {
  position: relative;
  text-align: center;
  /* height: 80px; */
  white-space: nowrap;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: baseline; */
  margin-bottom: 50px;
}
.eng {
  font-size: 60px;
  /* width: 40px; */
  white-space: nowrap;
  font-family: HarmonyOS_Sans_SC_Black;
  font-weight: 600;
  display: inline-block;
  color: rgba(255, 255, 255, 0);
  letter-spacing: 1px;
  -webkit-text-stroke: 1px #CC73FF;
  text-transform: uppercase;
  transform: skew(-15deg);
  opacity: 0.9;
  /* margin-left: -100%; */
  /* margin-right: -100%; */
}
.cha {
  font-size: 30px;
  font-family: HarmonyOS_Sans_SC;
  font-weight: 400;
  /* height: 80px; */
  letter-spacing: 1px;
  opacity: 0.85;
  /* color: #212121; */
  color: white;
  text-align: center;
  margin-top: -50px;
  /* position: absolute;
  bottom: 0; */
  /* position: absolute;
  left: 0;
  top: 0; */
}
</style>