<template>
  <div>
    <div class="header">
      <img src="../assets/recruit/hader.png" class="headerd" />
      <div class="search">
        <img src="../assets/recruit/search.png" class="search-icon" />
        <input class="input" v-model="keyword" @keyup.enter="handleClick" />
        <div class="button" @click="handleClick">查找岗位</div>
      </div>
    </div>
    <div class="backkk">
      <div class="hot">
        <dr-tag :title="title" :enName="enName" :color="color" class="tag" />
        <div class="job">
          <div class="jobname" v-if="categoryList.length !== 0">
            <div
              v-for="(item, index) in categoryList"
              :key="index"
              class="jobname-button"
              @click="choose(item, index)"
              :class="{
                last: index === categoryList.length - 1,
                active: active === index,
              }"
            >{{ item.name }}</div>
          </div>
          <div style="position: relative;">
            <!-- <transition-group name="title"> -->
            <div
              class="joblist"
              v-if="
                show !== '' &&
                show.children_list &&
                show.children_list.length !== 0
              "
              key="1"
            >
              <div
                v-for="item in show.children_list"
                :key="item.id"
                class="joblist-item"
                @click="open(item)"
              >
                <div class="joblist-item-title">
                  <div>{{ item.name }}</div>
                  <div class="hotd" v-if="item.is_pressing === 1">热</div>
                </div>
                <div class="joblist-item-content">
                  {{
                    item.parent_id === "-1"
                      ? item.tag
                      : change(item.parent_id)
                  }}
                  | {{ item.workspace }}
                </div>
              </div>
            </div>
            <div class="emtry" v-else key="2">
              <img src="../assets/recruit/emtry.png" style="width:100%" />
              <!-- <el-empty description="暂无岗位"></el-empty> -->
            </div>
            <!-- </transition-group> -->
          </div>
        </div>
      </div>
      <div class="contact">
        <dr-tag :title="title1" :enName="enName1" :color="color" />

        <div class="backgound">
          <div v-for="item in contactList" :key="item" class="backgound-c">
            <img :src="item.img" class="backgound-i" />
            <div class="backgound-t backgound-title">{{ item.title }}</div>
            <div class="backgound-t">{{ item.content }}</div>
          </div>
        </div>
      </div>
    </div>

    <transition name="title">
      <dr-job
        v-if="object !== ''"
        :object="object"
        @close="close"
        :joblist="joblist"
        :is_pressing="is_pressing"
        :id="id"
      />
    </transition>
  </div>
</template>
<script>
import DrJob from "../components/DrJob/index.vue";
import DrTag from "../components/DrTag/index.vue";
import { ElMessage } from "element-plus";
import { positonList, positonDetail, positonCategoryList } from "../utils/api";
export default {
  components: {
    DrJob,
    DrTag,
    // ElEmpty,
  },
  data() {
    return {
      color: "#212121",
      title: "热招岗位",
      enName: "hot job",
      title1: "联系我们",
      enName1: "contact",
      object: "",
      active: 0,
      show: false,
      keyword: null,
      joblist: [],
      is_pressing: null,
      id: null,
      itemid: null,
      jobshowlist: [],
      categoryList: [],
      contactList: [
        {
          img: require("../assets/recruit/address.png"),
          title: "联系地址",
          content: "广州市海珠区万胜围保利发展广场42楼",
        },
        {
          img: require("../assets/recruit/mail.png"),
          title: "联系邮箱",
          // content: "1105210307@xinyuan.com",
          content: "zhaopin@aoshugame.com",
        },
        // {
        //   img: require("../assets/recruit/phone.png"),
        //   title: "联系电话",
        //   content: "020-12345678",
        // },
      ],
    };
  },
  methods: {
    handleClick() {
      this.active = 0;
      this.positonList(this.keyword);
    },
    open(item) {
      positonDetail(item.id).then((res) => {
        // res.data.data.detail.type = item.parentId;
        this.object = res.data.data;
        this.is_pressing = item.is_pressing;
        this.id = item.id;
      });
    },
    close() {
      this.object = "";
      this.is_pressing = "";
      this.id = "";
    },
    choose(item, index) {
      this.active = index;
      console.warn(index, item.id);
      for (let i = 0; i < this.joblist.length; i++) {
        if (item.id == this.joblist[i].id) {
          // console.warn("scuss");
          this.show = this.joblist[i];
          return;
        }
        this.show = "";
      }
    },
    change(id) {
      for (let a = 0; a < this.categoryList.length; a++) {
        if (this.categoryList[a].id === id) {
          return this.categoryList[a].name;
        }
      }
    },
    //初始化
    first(res, search) {
      let a = {
        id: -1,
        name: "全部",
        children_list: [],
      };
      let s = res.data.data.list;
      s.forEach((element) => {
        a.children_list = a.children_list.concat(element.children_list);
      });
      if (!search) {
        this.joblist = [a].concat(res.data.data.list);
        this.show = this.joblist[0];
      } else {
        this.show = a
        this.active = -1;
      }

    },
    positonList(data) {
      let params = {
        keyword: data,
      };
      if (data) {
        positonList(params).then((res) => {
          console.warn(res.data.data.count, "ccc");
          if (res.data.data.count !== 0) {
            // console.warn(res.data.data.list, "ccc");
            this.first(res, true);
            console.warn(this.show, "show")
          } else {
            // this.joblist = ''
            ElMessage({
              showClose: true,
              message: "暂未提供该职位，请持续关注心源互动哦！",
              type: "error",
            });
          }
        });
      } else {
        positonList().then((res) => {
          // if (res.data.data.search_result === 1) {
          this.first(res);
          // }
          // console.warn(this.joblist, "job");
        });
      }
    },
    positonCategoryList() {
      positonCategoryList().then((res) => {
        let a = {
          id: -1,
          name: "全部",
        };
        this.categoryList = [a].concat(res.data.data.list);
      });
    },
  },
  created() {
    this.positonList();
    this.positonCategoryList();
  },
  mounted() {
    window.scrollTo({
      //滚动到元素位置

      top: 0,
      //top: 400//滚动到指定距离
      //top: 0,//滚动到顶部
      //top: document.documentElement.scrollHeight,//滚动到底部
      behavior: "smooth", // 平滑滚动
    });
  },
};
</script>
<style scoped>
.header {
  width: 100%;
  height: 309px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-image: url("../assets/recruit/header的背景.png");

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 100%; */
}
.backgound-title {
  margin-bottom: 2px;
}

.backkk {
  background: url("../assets/recruit/bg.jpg") no-repeat center;
  background-size: cover;
}
.headerd {
  position: absolute;
  left: 0;
  top: 0px;
  /* width: calc(100% ); */
  width: 100%;
  height: 310px;
}
.tag {
  margin-top: 47px;
}
.hotd {
  font-size: 15px;
  font-family: HarmonyOS_Sans_SC;
  font-weight: 400;
  color: #ffffff;
  display: inline-block;
  text-align: center;
  width: 26px;
  height: 23px;
  background: #fc4545;
  line-height: 23px;
}
.search {
  position: relative;
}

.imgd {
  display: flex;
  justify-content: center;
}
.input {
  width: calc(223px + 126px);
  display: block;
  padding: 18px 108px 18px 58px;
  height: 18px;
  font-size: 18px;
  background: rgba(151, 151, 151, 0.75);
  opacity: 0.5;
  outline: none;
  border: none;
  color: #fff;
  transition: all 0.2s ease-in;
}
.input:focus {
  outline: none;
  /* border:#87C6F9 1px solid; */
  /* box-shadow: 0 0 8px rgba(103, 166, 217, 1); */
  border: #CC73FF 1px solid;
  box-shadow: 0 0 8px rgb(226, 228, 115);
}
.search-icon {
  position: absolute;
  display: block;
  left: 24px;
  top: 14px;
  width: 25px;
  height: 25px;
}
.jobname {
  width: 158px;
  max-height: 599px;
  overflow: scroll;
  margin-right: 46px;
}

.jobname::-webkit-scrollbar {
  display: none;
}
.last {
  margin-bottom: 0 !important;
}
.search::after {
  content: "";
  display: block;
  width: 1px;
  height: 28px;
  position: absolute;
  right: 108px;
  top: 13px;
  background-color: #CC73FF;
}

.button {
  font-size: 18px;
  font-family: HarmonyOS_Sans_SC;
  font-weight: 400;
  color: #CC73FF;
  position: absolute;
  right: 21px;
  top: 15px;
  cursor: pointer;
}

.hot {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 53px;
}
.hot-img {
  width: 253px;
  height: auto;
  display: flex;
  margin-top: 42px;
  margin-bottom: 46px;
}
.joblist {
  width: 950px;
  /* max-height: 599px; */
  height: 599px;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

.emtry {
  /* position: absolute;
  left: 0;
  top: 0; */
  width: 950px;
  height: 599px;

  /* display: flex; */
  /* flex-wrap: wrap; */
  /* align-items: center;
  justify-content: center; */
  background: rgb(184, 181, 181);

  /* align-content: flex-start; */
}

.emtry >>> .el-empty {
  height: 599px;
  /* margin-top: 100px; */
}
.joblist::-webkit-scrollbar {
  width: 6px;
  background: white;
  /* background: #C8C8C8; */
  border-radius: 3px;
}

.joblist::-webkit-scrollbar-track {
  width: 6px;
  background: white;
  /* background: #c8c8c8; */
  border-radius: 3px;
}
.joblist::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #c8c8c8;
}
.jobname-button {
  font-size: 22px;
  font-family: HarmonyOS_Sans_SC;
  font-weight: 400;
  color: #212121;
  border: 1px solid #88898a;
  border-width: 1px 0px 1px 0px;
  transition: all 0.2s ease-in;
  text-align: center;
  white-space: nowrap;
  height: 70px;
  line-height: 70px;
  margin-bottom: 15px;
  background: url("../assets/recruit/1.png") 100% no-repeat;
  background-position: center;
  background-size: cover;
  cursor: pointer;
}
.jobname-button:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
}
.joblist-item {
  width: 363px;
  height: calc(25px + 62px + 25px);
  background: #ffffff;
  border: 1px solid #c4c3c3;
  padding: 34px 33px 34px 33px;
  margin-right: 31px;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease-in;
}
.joblist-item:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
}
.active {
  background-image: url("../assets/recruit/2.png") !important;
  color: #CC73FF !important;
}

.joblist-item-title {
  font-size: 25px;
  font-family: Source Han Sans CN;
  font-weight: 600;
  color: #3c3c3c;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.joblist-item-content {
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 20px;
  line-height: 20px;
  letter-spacing: 1px;
  color: rgba(60, 60, 60, 0.7);
}
.joblist-hot {
  position: absolute;
  top: 34px;
  right: 20px;
  width: 26px;
  height: 23px;
}

.job {
  display: flex;
}

.contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.backgound {
  background: url("../assets/recruit/4.png") 100% no-repeat;
  background-size: cover;
  display: flex;
  width: 1233px;
  justify-content: space-around;
  align-items: center;
  height: 140px;
  margin-bottom: 53px;
  letter-spacing: 2px;
}

.backgound-c {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 309px;
}
.backgound-i {
  /* width: 172px; */
  /* width: ; */
  height: 40px;
  display: block;
  margin-bottom: 5px;
}
.backgound-t {
  font-size: 18px;
  font-family: HarmonyOS_Sans_SC;
  font-weight: 400;
  color: #212121;
  text-align: center;
  white-space: nowrap;
  letter-spacing: 3px;
}
</style>